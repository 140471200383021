<template>
	<v-data-table
		:loading="loading"
		:headers="headers"
		:items="i18nPhrasesTranslatesList.items"
		:options.sync="options"
		:server-items-length="i18nPhrasesTranslatesList.total"
		calculate-widths
		class="elevation-1"
		:footer-props="{
			itemsPerPageOptions: [5,  15, 25, 50, 100],
			itemsPerPageText: 'Строк на стр.'
		}"
		loading-text="Загрузка данных"
	>
		<template v-slot:top>
			<v-toolbar flat>
				<v-toolbar-title>{{ title }}</v-toolbar-title>
				<v-divider vertical inset class="mx-6"/>
				<v-dialog
					v-model="dialogCreate"
					max-width="500px"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							color="primary"
							dark
							class="white--text"
							v-bind="attrs"
							v-on="on"
						>
							<v-icon left dark>mdi-plus</v-icon>
							Добавить
						</v-btn>
					</template>
					<v-card>
						<v-card-title>Новая подсказка</v-card-title>
						<v-card-text>
							<v-form
								ref="form"
								v-model="valid"
								lazy-validation
							>
								<v-container>
									<v-row>
										<v-col
											cols="12"
											sm="12"
											md="12"
										>
											<v-text-field
												v-model="newHint.name"
												label="Имя"
												outlined
												hide-details="auto"
												:rules="[rules.required]"
											/>
										</v-col>
									</v-row>
									<v-row>
										<v-col
											cols="12"
											sm="12"
											md="12"
										>
											<v-combobox
												v-model="newHint.place"
												label="Место где используется"
												outlined
												:items="i18nPhrasesPlaces.items"
												item-text="name"
												:return-object="false"
												:rules="[rules.required]"
												hide-details="auto"
												:search-input.sync="search"
											>
												<template v-slot:no-data>
													<v-list-item>
														<v-list-item-content>
															<v-list-item-title>
																Совпадений не найдено. Нажмите <kbd>Enter</kbd>, чтобы добавить новое <br> местоположение подсказки
															</v-list-item-title>
														</v-list-item-content>
													</v-list-item>
												</template>
											</v-combobox>
										</v-col>
									</v-row>
								</v-container>
							</v-form>
						</v-card-text>
						<v-card-actions>
							<v-spacer/>
							<v-btn
								color="grey darken-1"
								text
								@click="resetEditedItem"
							>
								Отменить
							</v-btn>
							<v-btn
								color="blue darken-1"
								text
								@click="createHint"
								:disabled="!valid"
							>
								Создать
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<v-spacer/>
				<v-col cols="6">
					<v-autocomplete
						outlined
						hide-details
						clearable
						dense
						no-data-text="Нет данных"
						:items="i18nPhrasesPlaces.items"
						item-text="name"
						:menu-props="{ top: false, offsetY: true }"
						label="Фильтр по местоположению"
						@change="updateFilters('place', $event)"
						v-model="filters.place"
					/>
				</v-col>
			</v-toolbar>
			<v-divider/>
		</template>
		<template v-slot:item.name="props">
			<v-label-editable
				v-model="props.item.name"
				:rules="[rules.required]"
				@input="saveEntity(props, 'Имя')"
			/>
		</template>
		<template v-slot:item.place="props">
			<v-label-editable
				v-model="props.item.place"
				:options="i18nPhrasesPlaces.items"
				:rules="[rules.required]"
				@input="saveEntity(props, 'Местоположение')"
			/>
		</template>
		<template v-slot:item.translate.ru-RU.value="props">
			<v-translation-editable
				v-model="props.item.translate['ru-RU'].value"
				@input="save(props, 'ru-RU')"
			/>
		</template>
		<template v-slot:item.translate.en-US.value="props">
			<v-translation-editable
				v-model="props.item.translate['en-US'].value"
				@input="save(props, 'en-US')"
			/>
		</template>
		<template v-slot:no-data>
			<v-btn
				color="primary"
				@click="initialize"
			>
				Сбросить
			</v-btn>
		</template>
	</v-data-table>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

import labelEditable from '@/components/labelEditable'
import translationEditable from '../components/translationEditable'

export default {
	metaInfo () {
		return {
			title: this.title
		}
	},

	components: {
		'v-label-editable': labelEditable,
		'v-translation-editable': translationEditable
	},

	data: function () {
		return {
			title: 'Подсказки',
			options: {},
			loading: true,
			place: '',
			headers: [
				{
					text: 'ID',
					align: 'start',
					sortable: false,
					value: 'id',
					divider: true
				},
				{
					text: 'Имя',
					sortable: false,
					value: 'name',
					divider: true,
					width: 250
				},
				{
					text: 'Место',
					sortable: false,
					value: 'place',
					divider: true
				},
				{
					text: 'Перевод RU',
					sortable: false,
					value: 'translate.ru-RU.value',
					divider: true
				},
				{
					text: 'Перевод EN',
					sortable: false,
					value: 'translate.en-US.value'
				}
			],
			filters: {
				place: ''
			},

			rules: {
				required: value => !!value || 'Поле обязательно'
			},

			dialogCreate: false,
			valid: true,
			newHint: {
				id: '',
				place: '',
				name: '',
				type: 'hint'
			},
			search: null
		}
	},

	computed: {
		...mapGetters({
			i18nPhrasesTranslatesList: 'i18n/i18nPhrasesTranslatesList',
			i18nPhrasesPlaces: 'i18n/i18nPhrasesPlaces'
		}),

		/* Фильтры */
		urlFilters () {
			/* Инициализация фильтров */
			const urlFilters = this.$route.query
			const filters = {}

			filters.page = +urlFilters.page || 1
			filters.limit = +urlFilters.limit || 15

			if (urlFilters.place) {
				filters.place = urlFilters.place
			}

			return filters
		}
	},
	watch: {
		/* Отслеживание изменения фильтров */
		'$route' () {
			this.initialize()
		},

		/* Отслеживание изменения состояния таблицы */
		options (to) {
			const filters = {}
			if (this.urlFilters.page !== to.page) {
				filters.page = to.page
			}

			if (this.urlFilters.limit !== to.itemsPerPage) {
				filters.limit = to.itemsPerPage
			}

			if (filters.page || filters.limit) {
				this.updateUrl(filters)
			}
		}
	},
	created () {
		/* Инициализация фильтров */
		this.$set(this, 'filters', this.urlFilters)
		this.initialize()
		this.initPlaceFilter()
	},

	methods: {
		...mapActions({
			getPhrasesTranslatesList: 'i18n/getPhrasesTranslatesList',
			getPlaces: 'i18n/getPlaces',
			putPhrasesTranslatesList: 'i18n/putPhrasesTranslatesList',
			postPhrasesTranslatesList: 'i18n/postPhrasesTranslatesList',
			deletePhrasesTranslatesList: 'i18n/deletePhrasesTranslatesList',
			putPhrasesList: 'i18n/putPhrasesList',
			postPhrasesList: 'i18n/postPhrasesList'
		}),
		async initialize () {
			this.loading = true

			this.$set(this, 'options', {
				...this.options,
				itemsPerPage: this.urlFilters.limit,
				page: this.urlFilters.page
			})

			try {
				await this.getDataFromApi()
			} catch (error) {
				this.$notify({
					type: 'error',
					title: error
				})
			}

			this.loading = false
		},
		async initPlaceFilter () {
			this.loading = true

			try {
				await this.getPlaces({ type: 'hint' })
			} catch (error) {
				this.$notify({
					type: 'error',
					title: error
				})
			}
		},

		async getDataFromApi () {
			this.loading = true

			await this.getPhrasesTranslatesList({
				limit: this.urlFilters.limit,
				offset: (this.urlFilters.page - 1) * this.urlFilters.limit,
				type: 'hint',
				place: this.urlFilters.place
			}
			).then(
				() => {
				},
				(error) => {
					this.$notify({
						type: 'error',
						title: error
					})
				}
			)

			this.loading = false
		},

		save (props, lang) {
			const payload = props.item.translate[`${lang}`]
			payload.phrase_id = props.item.id
			try {
				this[payload.id ? 'putPhrasesTranslatesList' : 'postPhrasesTranslatesList'](
					payload
				)
					.then(
						() => {
							this.$notify({ type: 'info', title: payload.id ? 'Перевод изменён' : 'Перевод добавлен' })
							this.getDataFromApi()
							this.initPlaceFilter()
						}, (error) => {
							this.$notify({
								type: 'error',
								title: error
							})
						}
					)
			} catch (error) {
				this.$notify({
					type: 'error',
					title: error
				})
			}
		},
		saveEntity (props, title) {
			const payload = {
				id: props.item.id,
				name: props.item.name,
				place: props.item.place,
				type: 'hint'
			}
			try {
				this.putPhrasesList(
					payload
				).then(
					() => {
						this.$notify({ type: 'info', title: `${title} подсказки изменено` })
						this.getDataFromApi()
						this.initPlaceFilter()
					}, (error) => {
						this.$notify({
							type: 'error',
							title: error
						})
					}
				)
			} catch (error) {
				this.$notify({
					type: 'error',
					title: error
				})
			}
		},

		updateFilters (filter, value) {
			const filters = {
				[filter]: value ? String(value) : undefined
			}

			const queryParams = this.$route.query

			switch (true) {
			case filter === 'place' && queryParams[filter] !== value && queryParams[filter] !== null && value !== null:
			case filter === 'limit':
				filters.page = undefined
				break
			}

			this.updateUrl(filters)
		},

		/* Обновление URL */
		async updateUrl (query) {
			await this.$router.push({
				query: {
					...this.$route.query,
					...query
				}
			})
		},

		/* Очистка данных диалогового окна */
		resetEditedItem () {
			this.$nextTick(() => {
				this.$set(this, 'newHint', {
					id: '',
					place: '',
					name: '',
					type: 'hint'
				})
				this.$set(this, 'dialogCreate', false)
			})
		},

		async createHint () {
			await this.$refs.form.validate()

			if (this.valid) {
				try {
					await this.postPhrasesList(this.newHint)
						.then(() => {
							this.$notify({ type: 'info', title: 'Подсказка добавлена' })
							this.getDataFromApi()
							this.initPlaceFilter()
							this.resetEditedItem()
						}, (error) => {
							this.$notify({
								type: 'error',
								title: error
							})
						}
						)
				} catch (error) {
					this.$notify({
						type: 'error',
						title: error
					})
				}
			}
		}
	}
}
</script>
