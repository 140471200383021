import { render, staticRenderFns } from "./translationEditable.vue?vue&type=template&id=1f5dfcea&scoped=true"
import script from "./translationEditable.vue?vue&type=script&lang=js"
export * from "./translationEditable.vue?vue&type=script&lang=js"
import style0 from "./translationEditable.vue?vue&type=style&index=0&id=1f5dfcea&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f5dfcea",
  null
  
)

export default component.exports