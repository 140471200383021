<template>
	<v-container
		v-if="edit"
		@keydown.esc="edit = false"
		@keydown.shift.enter.exact.prevent="editText"
	>
		<v-row>
			<v-textarea
				v-model="text"
				class="text-edit__field"
				placeholder="Введите текст подсказки"
				single-line
				counter
				autofocus
				auto-grow
			/>
		</v-row>
		<v-row class="text-edit__btns">
			<v-spacer/>
			<v-btn
				icon
				@click="edit = false"
				x-small
			>
				<v-icon>mdi-close</v-icon>
			</v-btn>
			<v-btn
				icon
				x-small
				@click="editText"
			>
				<v-icon>mdi-check</v-icon>
			</v-btn>
		</v-row>
	</v-container>
	<div
		v-else
		class="text-value"
		@click="edit = true"
	>
		<span>{{ value }}</span>
		<v-icon
			class="text-value__icon"
			small
			color="grey"
		>mdi-pencil</v-icon>
	</div>
</template>

<script>
export default {
	props: {
		value: {
			type: [String, Number],
			default: null
		}
	},

	data () {
		return {
			edit: false,
			text: undefined
		}
	},

	created () {
		this.$set(this, 'text', this.value)
	},

	methods: {
		editText () {
			this.$emit('input', this.text)
			this.edit = false
		}
	}
}
</script>

<style scoped lang="scss">
.text {
	&-edit {
		&__field {
			font-size: 14px;
		}

		&__btns {
			margin-bottom: 1px;
		}
	}
	&-value {
		display: flex;
		position: relative;
		max-width: calc(50vw - 400px);
		height: max-content;
		padding: 16px 0;
		cursor: pointer;
		overflow-wrap: break-word;

		span {
			width: 100%;
		}

		&__icon {
			position: absolute;
			bottom: 10px;
			right: -7px;
		}
	}
}
</style>
